<template>
  <div id="per_center">
    <div class="nav_menu">
      <div class="nav_menu_title">
        <div class="nav_menu_title_img">
          <img src="@/assets/image/PersonalCenter/personalCenterLogo.png" alt="">
        </div>
        <div class="nav_menu_title_text">个人中心</div>
      </div>
      <div class="nav_menu_content">
        <el-menu
          router
          :default-active="this.$route.path"
          :unique-opened="true"
          class="nav_menu-list"
          @open="handleOpen"
          @close="handleClose">
          <!-- 我的账号 -->
          <el-menu-item index="/personalCenter/myAccount">
            <span slot="title">我的账号</span>
            <div></div>
          </el-menu-item>
<!--          &lt;!&ndash; 我是卖家 &ndash;&gt;-->
<!--          <el-menu-item index="/personalCenter/seller">-->
<!--            <span slot="title">我是卖家</span>-->
<!--            <div></div>-->
<!--          </el-menu-item>-->

          <!-- 我是卖家 -->
          <el-submenu index="seller">
            <template slot="title">
              <span>我是卖家</span>
              <div></div>
            </template>
            <el-menu-item index="/personalCenter/seller"><div></div>游戏账号</el-menu-item>
            <el-menu-item index="/personalCenter/seller/gold"><div></div>游戏金币</el-menu-item>
            <el-menu-item index="/personalCenter/seller/equip"><div></div>游戏装备</el-menu-item>
<!--            <el-menu-item index="/personalCenter/seller/bargain"><div></div>收到的降价请求</el-menu-item>-->
          </el-submenu>
<!--          &lt;!&ndash; 我是买家 &ndash;&gt;-->
<!--          <el-menu-item index="/personalCenter/buyer">-->
<!--            <span slot="title">我是买家</span>-->
<!--            <div></div>-->
<!--          </el-menu-item>-->

          <!-- 我是买家 -->
          <el-submenu index="buyer">
            <template slot="title">
              <span>我是买家</span>
              <div></div>
            </template>
            <el-menu-item index="/personalCenter/buyer"><div></div>账号订单</el-menu-item>
            <el-menu-item index="/personalCenter/buyer/gold"><div></div>金币订单</el-menu-item>
            <el-menu-item index="/personalCenter/buyer/equip"><div></div>装备订单</el-menu-item>
<!--            <el-menu-item index="/personalCenter/buyer/bargain"><div></div>发出的降价请求</el-menu-item>-->
          </el-submenu>

<!--          &lt;!&ndash; 我的收藏 &ndash;&gt;
          <el-submenu index="myCollection">
            <template slot="title">
              <span>我的收藏</span>
              <div></div>
            </template>
            <el-menu-item index="/personalCenter/myCollection"><div></div>游戏账号</el-menu-item>
            <el-menu-item index="/personalCenter/myCollection/gold"><div></div>游戏金币</el-menu-item>
            <el-menu-item index="/personalCenter/myCollection/equip"><div></div>游戏装备</el-menu-item>
          </el-submenu>-->
          <!-- 咨询记录 -->
          <el-menu-item index="/personalCenter/serHistory">
            <span slot="title">咨询记录</span>
            <div></div>
          </el-menu-item>
          <!-- 最近浏览 -->
          <el-menu-item index="/personalCenter/accHistory">
            <span slot="title">最近浏览</span>
            <div></div>
          </el-menu-item>
          <el-submenu index="user_info">
            <template slot="title">
              <span>个人信息</span>
              <div></div>
            </template>
              <el-menu-item index="/personalCenter/personalInfo"><div></div>基本信息</el-menu-item>
              <el-menu-item index="/personalCenter/personalInfo/rNameAuthentication"><div></div>实名认证</el-menu-item>
              <el-menu-item index="/personalCenter/personalInfo/modifyLoginPwd"><div></div>修改登录密码</el-menu-item>
              <el-menu-item index="/personalCenter/personalInfo/withdrawalAccount"><div></div>设置提现账户</el-menu-item>
              <el-menu-item index="/personalCenter/personalInfo/paymentPwd"><div></div>支付密码</el-menu-item>
              <el-menu-item index="/personalCenter/personalInfo/changeTelphone"><div></div>换绑手机号</el-menu-item>
          </el-submenu>
<!--          &lt;!&ndash; 财务中心 &ndash;&gt;
          <el-submenu index="financial_center">
            <template slot="title">
              <span>财务中心</span>
              <div></div>
            </template>
            <el-menu-item index="/personalCenter/financialCenter"><div></div>充值</el-menu-item>
            <el-menu-item index="/personalCenter/financialCenter/withdraw"><div></div>提现</el-menu-item>
            <el-menu-item index="/personalCenter/financialCenter/withdrawRecord"><div></div>提现记录</el-menu-item>
            <el-menu-item index="/personalCenter/financialCenter/moneyDetails"><div></div>收支明细</el-menu-item>
          </el-submenu>-->
          <!-- 站内通知 -->
          <el-menu-item index="/personalCenter/siteNotice">
            <span slot="title">站内通知</span>
            <div></div>
          </el-menu-item>
<!--          &lt;!&ndash; 聊天室列表 &ndash;&gt;
          <el-menu-item index="/chat/list">
            <span slot="title" class="chatList">
              聊天室
              <span id="message" v-show="this.$store.state.message>0"></span>
            </span>
            <div></div>
          </el-menu-item>
          &lt;!&ndash; 合同中心 &ndash;&gt;
          <el-submenu index="contract_center">
            <template slot="title">
              <span>合同中心</span>
              <div></div>
            </template>
            <el-menu-item index="/personalCenter/contractCenter"><div></div>合同信息</el-menu-item>
          </el-submenu>-->
        </el-menu>
      </div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>

</template>
<script>
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {

    }
  },
  methods: {
    handleOpen(key, keyPath) {
      // if(key!='financial_center' && key !='user_info'){
      //   this.$router.push({
      //     path:keyPath[0]
      //   });
      // }
    },
    handleClose(key, keyPath) {
    }
  },
  mounted () {

  },
  watch: {

  },
  computed: {

  }
}
</script>

<style lang='less' scoped>
.chatList{
  position: absolute;
  #message{
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #F56B6C;
    position: absolute;
    top: 10px;
    right: -5px;
  }
}
  #per_center{
    width: 1200px;
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    .nav_menu{
      width: 243px;
      //height: 727px;
      margin-right: 9px;
      border-radius: 20px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      .nav_menu_title{
        height: 72px;
        background: #FFC121;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px 0 32px;
        .nav_menu_title_img{
          width: 30px;
          height: 30px;
          margin-right: 7px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .nav_menu_title_text{
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          margin-right: 58px;
        }
      }
      .nav_menu_content{
        padding-top: 11px;
        height: 644px;
        background: #FFFFFF;
        border-radius: 0px 0px 20px 20px;
        .nav_menu-list>.el-menu-item {
          height: 40px;
          line-height: 40px;
          padding-left: 59px !important;
          position: relative;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
          div {
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 5px;
            background: #000000;
            position: absolute;
            top: 17px;
            left: 30px;
          }
        }
        .nav_menu-list>.is-active ,.nav_menu-list>.el-menu-item:hover{
          color: #FFFFFF !important;
          background: #283748;
          div{
            background: #FFFFFF;
          }
        }
        /deep/.el-menu-item, /deep/.el-submenu__title{
          height: 40px;
          line-height: 40px;
          padding-left: 59px !important;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
        }
        /deep/.is-active>.el-submenu__title{
          color: #FFFFFF !important;
          div{
            background: #FFFFFF !important;
          }
        }
        /deep/.el-submenu__title:hover,.el-submenu__title:focus{
          color: #FFFFFF;
          background: #283748;
          div{
            background: #FFFFFF !important;
          }
        }
        /deep/.el-submenu>.el-submenu__title{
          div{
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 5px;
            background: #000000;
            position: absolute;
            top: 17px;
            left: 30px;
          }
        }
        /deep/.el-menu--inline{
          background: #F4F4F4;
        }
        /deep/.el-menu--inline>.el-menu-item{
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
        }
        /deep/.el-menu--inline>.el-menu-item:hover{
          background: #E2E2E2;
          position: relative;
          div{
            position: absolute;
            width: 2px;
            height: 16px;
            background: #8F8F8F;
            border-radius: 1px;
            top: 12px;
            left: 35px;
          }
        }
        /deep/.el-submenu>.el-menu--inline>.is-active{
          background: #E2E2E2;
          position: relative;
          div{
            position: absolute;
            width: 2px;
            height: 16px;
            background: #8F8F8F;
            border-radius: 1px;
            top: 12px;
            left: 35px;
          }
        }
        /*菜单关闭*/
        /deep/.el-submenu>.el-submenu__title .el-submenu__icon-arrow{
          -webkit-transform: rotateZ(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotateZ(-90deg);
        }
        /*菜单展开*/
        /deep/.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow{
          -webkit-transform: rotateZ(0deg);
          -ms-transform: rotate(0deg);
          transform: rotateZ(0deg);
        }
      }
    }
    .main{
      width: 951px;
      //height: 727px;
      background: #FFFFFF;
    }
  }
</style>
